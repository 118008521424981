import moment from 'moment/moment'

export default class Overlap {
  constructor(overlap) {
    this.date = moment(overlap.date)
    this.leaves = overlap.leaves ?? []
    this.nonVisibleLeaveCount = overlap.nonvisible_leave_count
    this.nonvisibleEmploymentCount = overlap.nonvisible_employment_count
    this.leaveLimitScope = overlap.leave_limit_scope
  }

  isLeaveLimitExceeded() {
    return this.leaveLimitScope !== null
  }
}
