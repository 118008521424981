import moment from 'moment-timezone'
import { flatMap, groupBy, omit, uniqBy } from 'lodash-es'
import SingleEmploymentLeaveRequest from '@/models/leave/drafting/SingleEmploymentLeaveRequest'

export default class MultipleEmploymentLeaveRequest extends SingleEmploymentLeaveRequest {
  _mergeOverlapLeaveErrors(employments) {
    const overlapLeavePerBreakdown = this._findOverlapsPerBreakdown(employments)
    const self = this

    overlapLeavePerBreakdown.forEach(function(dayOverlap, dayNumber) {
      if (dayOverlap.length) {
        self._mapOverlapForBreakdown(employments, dayNumber, dayOverlap)
      }
    })

    return employments
  }

  _mapOverlapForBreakdown(employments, dayNumber, dayOverlap) {
    const self = this

    employments.forEach(function(employment) {
      const dayBreakdown = employment.breakdowns[dayNumber]

      const overlapErrorIndex = self._findOverlapErrorIndex(dayBreakdown)

      if (overlapErrorIndex !== -1) {
        dayBreakdown.messages.errors[overlapErrorIndex] = {
          code: 'overlap_with_existing_leave',
          leaves: dayOverlap,
        }
      }
    })
  }

  _findOverlapsPerBreakdown(employments) {
    const overlapLeavePerBreakdown = []
    const totalDays = employments[0].breakdowns.length

    for (let day = 0; day < totalDays; day++) {
      overlapLeavePerBreakdown[day] = this._findOverlapLeaveForBreakdown(
        employments,
        day
      )
    }

    return overlapLeavePerBreakdown
  }

  _findOverlapLeaveForBreakdown(employments, breakdown) {
    const overlapLeavePerBreakdown = []
    const self = this

    employments.forEach(function(owner) {
      const dayBreakdown = owner.breakdowns[breakdown]

      const overlapError = dayBreakdown.messages.errors.find(
        error => error.code === 'overlap_with_existing_leave'
      )

      if (overlapError) {
        overlapLeavePerBreakdown.push(self._mapLeave(overlapError.leave, owner))
      }
    })

    return overlapLeavePerBreakdown
  }

  _mapLeave(leave, owner) {
    return omit(
      {
        ...this._mapLeaveOwner(leave, owner),
        id: leave.leave_id,
      },
      'leave_id'
    )
  }

  _mapLeaveOwner(leave, owner) {
    return {
      ...leave,
      owner_id: owner.id,
      owner: {
        id: owner.id,
        first_name: owner.first_name,
        last_name: owner.last_name,
        full_name: owner.full_name,
        avatar_url: owner.avatar,
      },
    }
  }

  get breakdowns() {
    return this._getBreakdownsPerDate()
  }

  get workingSchedule() {
    return this.companyWorkingSchedule
  }

  get showCustomDuration() {
    return this.items.every(item => item.owner.uses_company_schedule)
  }

  get companyWorkingSchedule() {
    const isEachOwnerUsesCompanySchedule = this.items.every(
      draftedLeave =>
        draftedLeave.owner.availableWorkingSchedule.isCompanyWorkingSchedule
    )

    return isEachOwnerUsesCompanySchedule
      ? this.items[0].owner.company.activeWorkingSchedule
      : null
  }

  generateBreakdownsToRedraft(pickedBreakdowns) {
    return this._generateBreakdownsForOwnersUsingPickedBreakdowns(
      pickedBreakdowns
    )
  }

  _getBreakdownsPerDate() {
    const groupedBreakdownsPerDate = this._groupBreakdownsPerDate()

    return groupedBreakdownsPerDate.map(([breakdownDate, breakdowns]) => {
      const newBreakdown = this.makeBreakdown(breakdownDate, breakdowns)

      this._setActiveDurationForLeaveRequests(
        newBreakdown.activeDuration.name,
        breakdownDate
      )

      return newBreakdown
    })
  }

  makeBreakdown(breakdownDate, breakdowns) {
    const filteredBreakdown = this._hasWorkingDayBreakdowns(breakdowns)
      ? this._filterWorkingDayBreakdowns(breakdowns)[0]
      : breakdowns[0]

    const newBreakdown = filteredBreakdown.clone()

    newBreakdown.setAvailableDurations()
    newBreakdown.setActiveDuration()
    newBreakdown.setMessages(this._generateCommonMessagesFrom(breakdowns))

    return newBreakdown
  }

  _setActiveDurationForLeaveRequests(durationName, breakdownDate) {
    this.items.forEach(leaveRequest => {
      const breakdown = leaveRequest.findBreakdownForDate(
        moment.utc(breakdownDate, 'YYYY-MM-DD')
      )

      breakdown.rawBreakdown.duration.name = durationName
    })
  }

  _groupBreakdownsPerDate() {
    const sortedLeaveRequests = this._sortLeaveRequestsForEmploymentsWithCompanyWorkingScheduleFirst()

    return Object.entries(
      groupBy(
        sortedLeaveRequests.flatMap(leaveRequest => leaveRequest.breakdowns),
        breakdown => breakdown.date.format('YYYY-MM-DD')
      )
    )
  }

  _sortLeaveRequestsForEmploymentsWithCompanyWorkingScheduleFirst() {
    return [
      ...this._getLeaveRequestsForEmploymentsWithCompanyWorkingSchedule(),
      ...this._getLeaveRequestsForEmploymentsWithCustomWorkingSchedules(),
    ]
  }

  _getLeaveRequestsForEmploymentsWithCompanyWorkingSchedule() {
    return this.items.filter(
      leaveRequest => leaveRequest.owner.uses_company_schedule
    )
  }

  _getLeaveRequestsForEmploymentsWithCustomWorkingSchedules() {
    return this.items.filter(
      leaveRequest => !leaveRequest.owner.uses_company_schedule
    )
  }

  _hasWorkingDayBreakdowns(breakdowns) {
    return breakdowns.some(breakdown => breakdown.isWorkingDay)
  }

  _filterWorkingDayBreakdowns(breakdowns) {
    return breakdowns.filter(breakdown => breakdown.isWorkingDay)
  }

  _generateCommonMessagesFrom(breakdowns) {
    return {
      errors: this._uniqueErrorMessages(breakdowns),
      info: this._uniqueInfoMessages(breakdowns),
      warnings: this._uniqueWarningMessages(breakdowns),
    }
  }

  _uniqueErrorMessages(breakdowns) {
    const messages = []
    let overlapLeaveMessageIndex = null

    flatMap(breakdowns, `rawBreakdown.messages.errors`).forEach(error => {
      if (error.code === 'overlap_with_existing_leave') {
        if (overlapLeaveMessageIndex === null) {
          overlapLeaveMessageIndex = messages.length
          messages.push({ ...error })
        } else {
          messages[overlapLeaveMessageIndex].leaves = uniqBy(
            [...messages[overlapLeaveMessageIndex].leaves, ...error.leaves],
            'id'
          )
        }
      } else {
        messages.push(error)
      }
    })

    return uniqBy(messages, 'code')
  }

  _uniqueInfoMessages(breakdowns) {
    return this._uniqueByMessages(breakdowns, 'info')
  }

  _uniqueByMessages(breakdowns, type) {
    return uniqBy(flatMap(breakdowns, `rawBreakdown.messages.${type}`), 'code')
  }

  _uniqueWarningMessages(breakdowns) {
    const messages = []
    let leaveLimitMessageIndex = null

    flatMap(breakdowns, `rawBreakdown.messages.warnings`).forEach(warning => {
      if (warning.code === 'leave_limit_reached') {
        if (leaveLimitMessageIndex === null) {
          leaveLimitMessageIndex = messages.length
          messages.push({ ...warning })
        } else {
          messages[leaveLimitMessageIndex].leaves = uniqBy(
            [...messages[leaveLimitMessageIndex].leaves, ...warning.leaves],
            'id'
          )
        }
      } else {
        messages.push(warning)
      }
    })

    return uniqBy(messages, 'code')
  }

  _generateSuitableBreakdown(pickedBreakdowns, draftedLeave) {
    return {
      owner_id: draftedLeave.owner.getKey(),
      breakdowns: draftedLeave.breakdowns.map(breakdown => {
        const pickedBreakdown = pickedBreakdowns.find(pickedBreakdown =>
          pickedBreakdown.isSameDate(breakdown.date)
        )

        let duration = this._findSuitableDuration(pickedBreakdown, breakdown)

        return {
          type: breakdown.type,
          ...duration.toJson(),
        }
      }),
    }
  }

  _findSuitableDuration(pickedBreakdown, breakdown) {
    if (pickedBreakdown.activeDuration.isCustom) {
      return pickedBreakdown.activeDuration
    }

    return breakdown.findDuration(pickedBreakdown.activeDuration.name)
  }
}
