import config from '@/config'

const titlePrefix = 'Leave Dates |'
const settingPrefix = 'Settings |'
const reportPrefix = 'Reports |'

const page = path => () =>
  import(/* webpackChunkName: '' */ `@/pages/${path}`).then(m => m.default || m)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/wall-chart',
  },
  {
    path: '/signin',
    name: 'signin',
    component: page('auth/SignIn.vue'),
    meta: {
      title: `${titlePrefix} Log In`,
      description: 'Log into your Leave Dates account.',
      canonical: `${config.app.url}/signin`,
    },
  },
  {
    path: '/signup',
    name: 'signup',
    component: page('auth/SignUp.vue'),
    meta: {
      title: `${titlePrefix} Sign Up`,
      description: 'Create a free Leave Dates calendar for your organisation.',
      canonical: `${config.app.url}/signup`,
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: page('auth/ForgotPassword.vue'),
    meta: {
      title: `${titlePrefix} Forgot Password`,
      canonical: `${config.app.url}/forgot-password`,
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: page('auth/ResetPassword.vue'),
    meta: {
      title: `${titlePrefix} Reset Password`,
      canonical: `${config.app.url}/reset-password`,
    },
  },
  {
    path: '/verify-account',
    name: 'verify-account',
    component: page('auth/VerifyAccount.vue'),
    meta: {
      title: `${titlePrefix} Verify Account`,
    },
  },
  {
    path: '/pending-email/verify/:user/:email',
    name: 'pending-email-verify',
    component: page('auth/PendingEmailVerify.vue'),
    meta: {
      title: `${titlePrefix} Pending Email Verify`,
    },
  },
  {
    path: '/impersonate/:email',
    name: 'impersonate',
    component: page('auth/Impersonate.vue'),
    meta: {
      title: `Leave Dates`,
    },
  },
  {
    path: '/unimpersonate',
    name: 'unimpersonate',
    component: page('auth/Unimpersonate.vue'),
    meta: {
      title: `Leave Dates`,
    },
  },
  {
    path: '/setup-company',
    name: 'setup-company',
    component: page('SetupCompany.vue'),
    meta: {
      title: `${titlePrefix} Setup Company`,
    },
  },
  {
    path: '/verify-invitation',
    name: 'verify-invitation',
    component: page('VerifyInvitation.vue'),
    meta: {
      title: `${titlePrefix} Verify Invitation`,
    },
  },
  {
    path: '/company-domain',
    name: 'company-domain',
    component: page('auth/CompanyDomain.vue'),
    meta: {
      title: `${titlePrefix} Company`,
      canonical: `${config.app.url}/company-domain`,
    },
  },
  {
    path: '/leave-summary',
    name: 'leave-summary',
    component: page('LeaveSummary.vue'),
    meta: {
      company: true,
      title: `${titlePrefix} Leave Summary`,
    },
  },
  {
    path: '/wall-chart',
    name: 'wall-chart',
    component: page('WallChart.vue'),
    meta: {
      company: true,
      title: `${titlePrefix} Wall Chart`,
    },
  },
  {
    path: '/my-year',
    name: 'my-year',
    component: page('MyYear.vue'),
    meta: {
      company: true,
      title: `${titlePrefix} My Year`,
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: page('Notifications.vue'),
    meta: {
      title: `${titlePrefix} Notifications`,
    },
  },
  {
    path: '/my-profile',
    name: 'my-profile',
    component: page('MyProfile.vue'),
    meta: {
      title: `${titlePrefix} My Profile`,
    },
  },
  {
    path: '/companies',
    name: 'companies',
    component: page('Companies.vue'),
    meta: {
      title: `${titlePrefix} Companies`,
    },
  },
  {
    path: '/reports',
    components: {
      default: page('reports/Reports.vue'),
      'secondary-nav': () => import('@/components/ReportsNavbar.vue'),
    },
    redirect: '/reports/employees',
    meta: {
      title: `${titlePrefix} Reports`,
    },
    children: [
      {
        path: 'allowances',
        name: 'reports-allowances',
        component: page('reports/AllowancesReport.vue'),
        meta: {
          company: true,
          title: `${reportPrefix} Allowances`,
        },
      },
      {
        path: 'leave',
        name: 'reports-leave',
        component: page('reports/LeaveReport.vue'),
        meta: {
          company: true,
          title: `${reportPrefix} Leave`,
        },
      },
      {
        path: 'overtime',
        name: 'reports-overtime',
        component: page('reports/OvertimeReport.vue'),
        meta: {
          company: true,
          title: `${reportPrefix} Overtime`,
        },
      },
      {
        path: 'time-off-in-lieu',
        redirect: {
          name: 'reports-overtime',
        },
      },
      {
        path: 'employment-holidays',
        name: 'reports-employment-holidays',
        component: page('reports/EmploymentHolidaysReport.vue'),
        meta: {
          company: true,
          title: `${reportPrefix} Public Holidays`,
        },
      },
      {
        path: 'employees',
        name: 'reports-employee',
        component: page('reports/EmploymentsReport.vue'),
        meta: {
          company: true,
          title: `${reportPrefix} Employees`,
        },
      },
      {
        path: 'approver',
        name: 'reports-approver',
        component: page('reports/ApproverReport.vue'),
        meta: {
          company: true,
          title: `${reportPrefix} Approver`,
        },
      },
    ],
  },
  {
    path: '/settings',
    components: {
      default: page('settings/Settings.vue'),
      'secondary-nav': () => import('@/components/SettingsNavbar.vue'),
    },
    redirect: '/settings/company',
    meta: {
      title: `${titlePrefix} Settings`,
    },
    children: [
      {
        path: 'company',
        name: 'company',
        component: page('settings/Company.vue'),
        meta: {
          company: true,
          title: `${settingPrefix} Company`,
        },
      },
      {
        path: 'departments',
        name: 'departments',
        component: page('settings/Departments.vue'),
        meta: {
          company: true,
          title: `${settingPrefix} Departments`,
        },
      },
      {
        path: 'allowances',
        name: 'allowances',
        component: page('settings/Allowances.vue'),
        meta: {
          company: true,
          title: `${settingPrefix} Allowances`,
        },
      },
      {
        path: 'overtime',
        name: 'overtime',
        component: page('settings/Overtime.vue'),
        meta: {
          company: true,
          title: `${settingPrefix} Overtime`,
        },
      },
      {
        path: 'leave-types',
        name: 'leave-types',
        component: page('settings/LeaveTypes.vue'),
        meta: {
          company: true,
          title: `${settingPrefix} Leave Types`,
        },
      },
      {
        path: 'employees',
        name: 'employees',
        component: page('settings/Employees.vue'),
        meta: {
          company: true,
          title: `${settingPrefix} Employees`,
        },
      },
      {
        path: 'calendars',
        name: 'calendars',
        component: page('settings/Calendars.vue'),
        meta: {
          company: true,
          title: `${settingPrefix} Calendars`,
        },
      },
      {
        path: 'holidays',
        name: 'holidays',
        component: page('settings/Holidays.vue'),
        meta: {
          company: true,
          title: `${settingPrefix} Holidays`,
        },
      },
      {
        path: 'limits',
        name: 'limits',
        component: page('settings/Limits.vue'),
        meta: {
          company: true,
          title: `${settingPrefix} Limits`,
        },
      },
      {
        path: 'import',
        name: 'import',
        component: page('settings/Import.vue'),
        meta: {
          company: true,
          title: `${settingPrefix} Import`,
        },
      },
      {
        path: 'integrations/:continue?',
        name: 'integrations',
        component: page('Integrations.vue'),
        meta: {
          company: true,
          title: `${settingPrefix} Integrations`,
        },
      },
      {
        path: 'billing',
        name: 'billing',
        component: page('settings/BillingContainer.vue'),
        meta: {
          company: true,
          title: `${settingPrefix} Billing`,
        },
      },
    ],
  },
  {
    path: '/unsubscribe',
    name: 'unsubscribe',
    component: page('Unsubscribed.vue'),
    meta: {
      title: `${titlePrefix} Unsubscribe`,
      description: 'Unsubscription from all email summary reports.',
    },
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: page('Unauthorized.vue'),
    meta: {
      title: `${titlePrefix} Unauthorized`,
    },
  },
  {
    path: '*',
    name: 'not-found',
    component: page('error/NotFound.vue'),
    meta: {
      title: `${titlePrefix} Not Found`,
    },
  },
]

export default routes
